import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="KitchenLab &bull; Contact" />
        <HeaderGeneric />
        <div id="main">
          {/* <section id="content" className="main">
            <h2>Contact</h2>
            <p>Something</p>
          </section> */}

          <section id="content" className="main">
            <iframe
              style={{ height: '500px', width: '100%', display: 'block' }}
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=149%2013th%20st,%20Charlestown,%20MA,%2002129,%20USA+(CVRC)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
